import * as React from "react"
import PageLayout from "../../../components/page-layout"
import ExternalLink from "../../../components/external-link"
import Navbar from "../../../components/navbar"
import { Link } from "gatsby"

import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

import Img1 from "./img/1.jpg"
import Img2 from "./img/2.jpg"
import Img3 from "./img/3.jpg"
import Img4 from "./img/4.jpg"
import Img5 from "./img/5.jpg"
import Img6 from "./img/6.jpg"
import Img7 from "./img/7.jpg"

import "../style.css"
import "./style.css"

const SuicunePage = () => {
  return (
    <PageLayout title="fb messages | grac">
      <body id="fb-msgs-page" className="page">
        <div className="center-container">
          <Navbar />

          {/* PROJECTS */}
          <div className="page-block">
            <div>
              <p>
                <Link to="/play">&larr; back</Link>
              </p>
              <div style={{ height: "20px" }} />
              <h2>Facebook Messages</h2>
              <p className="project-description">
                <em>built in 2020</em>
              </p>

              
              <h3>Introduction</h3>
              <p>
                I've been using Facebook as my primary means of communicating
                with my friends and family since 2012. Because of this, I wanted
                to analyze my messaging habits and history over the past 8
                years, looking at metrics such as how frequently I talk to
                different friends throughout different periods of my life, how
                the language I have used has changed over time, my "happiness"
                trends based off NLP analysis, and more.
                <br/><br/>
                View the full project description on my <ExternalLink href="https://github.com/gracejiang/fb-msgs">GitHub</ExternalLink>!
              </p>

              <div style={{ height: "30px" }} />

              <h3>Basic Metric Analysis</h3>
              <ul id="metrics">
                <li><strong>Total Number of Messages in All Conversations:</strong> 2,732,052</li>
                <li><strong>Total Number of Messages Sent:</strong> 1,287,000</li>
                <li><strong>Total Number of Messages Received:</strong> 1,445,052</li>
                <li><strong>Number of Different Conversations:</strong> 2,557</li>
              </ul>

              <div style={{ height: "30px" }} />

              <Carousel>
                <div>
                  <img src={Img1} alt="pic" />
                  <p className="legend">
                  Messages sent and received over time
                  </p>
                </div>
                <div>
                  <img src={Img2} alt="pic" />
                  <p className="legend">
                  Messages received from close friends over time
                  </p>
                </div>
                <div>
                  <img src={Img3} alt="pic" />
                  <p className="legend">
                  Messages received from close friends over time, excluding outliers
                  </p>
                </div>
                <div>
                  <img src={Img4} alt="pic" />
                  <p className="legend">
                  Happiness sentiment over time
                  </p>
                </div>
                <div>
                  <img src={Img5} alt="pic" />
                  <p className="legend">
                  Word Cloud of 2012. <em>*Note: The "d" and "3" are probably from 12y/o me using ":D" and ":3" emoticons excessively</em>
                  </p>
                </div>
                <div>
                  <img src={Img6} alt="pic" />
                  <p className="legend">
                  Word Cloud of 2016
                  </p>
                </div>
                {/* <div>
                  <img src={Img7} alt="pic" />
                  <p className="legend">
                  Word Cloud of 2020
                  </p>
                </div> */}
               
              </Carousel>
            </div>
          </div>
        </div>
      </body>
    </PageLayout>
  )
}

export default SuicunePage
